<template>
  <div>
    <Dialog
      :id="'dialogDiretorioSelecionar'"
      ref="dialogDiretorioSelecionar"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'md'"
      :classModalBody="'mx-0 my-0'"
      :titulo="'Selecionar'"
      @dialog-off="fechar()"
    >
      <div>
        <div>
          <DiretorioList
            ref="selecionarDiretorio"
            instancia="selecionarDiretorio"
            tipo="local"
            :funcao="funcao"
            @dialog-on="$refs.dialogDiretorioSelecionar.fechar()"
            @dialog-off="$refs.dialogDiretorioSelecionar.abrir()"
          />

          <div class="separator separator-dashed my-4"></div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="fechar()"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" @click="selecionar()">
              Selecionar
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import DiretorioList from "./DiretorioList.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";

export default {
  components: { Dialog, DiretorioList },
  props: {
    instancia: { type: String, default: "" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },

  data() {
    return {
      itens: [],
      aba: "local",
      funcao: "selecionar-diretorio",
    };
  },

  mounted() {},

  methods: {
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogDiretorioSelecionar.abrir();
    },
    fechar() {
      //* Limpa os itens selecionados
      this.storeArvore.instancias["selecionarDiretorio"].selecaoMultipla = {};

      //* Fecha a janela
      this.$refs.dialogDiretorioSelecionar.fechar();

      //* Emite o evento
      this.$emit("dialog-off");
    },
    async diretorioAtual(tipo, caminho, diretorioId, nuvem, token) {
      //* Define o tipo de instancia
      this.storeArvore.instancias["selecionarDiretorio"].tipo = tipo;

      //* Limpa a conexao a outra nuvem
      this.storeAuth.token["selecionarDiretorio"] = "";
      this.storeAuth.nuvem["selecionarDiretorio"] = null;

      //* Verifica se esta conectando nos diretorios locais
      if (tipo == "remoto") {
        //* Verifica se conectou na mesma sessao do usuario conectado
        if (token !== this.storeAuth.sessao.token) {
          //* Carrega o usuario
          var apiUsuario = await api.get(process.env.VUE_APP_URL_API + "/admin/sessao/refresh", token);

          //* Verifica se ocorreu algum erro
          if (apiUsuario.data.erro == false) {
            //* Define o token da nuvem conectada
            this.storeAuth.token["selecionarDiretorio"] = token;

            //* Define os dados da nuvem
            this.storeAuth.nuvem["selecionarDiretorio"] =
              apiUsuario.data.item.usuario.nuvem;

            //* Armazena o usuario autenticado da nuvem
            this.storeAuth.nuvem["selecionarDiretorio"].usuarioNuvem =
              apiUsuario.data.item.usuario;
          }
        }

        //* Recarrega a arvore
        await this.storeArvore.recarregarInstancia(
          "selecionarDiretorio",
          tipo,
          false
        );

        //* Posiciona a janela no diretorio
        this.storeArvore.diretorioAbrir("selecionarDiretorio", {
          _id: diretorioId,
          fullPath: caminho,
          nome: "",
        });
      } else {
        //* Recarrega a arvore
        await this.storeArvore.recarregarInstancia(
          "selecionarDiretorio",
          tipo,
          false
        );

        //* Posiciona a janela no diretorio
        this.storeArvore.diretorioAbrir("selecionarDiretorio", {
          fullPath: caminho,
        });
      }
    },
    async selecionar() {
      
      //* Define os dados da instancia
      var dadosInstancia = this.storeArvore.instancias["selecionarDiretorio"];

      //* Verifica se esta selecionando um diretorio
      if (this.funcao == "selecionar-diretorio") {
        var nuvem = null;
        var token = "";

        if (
          dadosInstancia.tipo == "remoto" &&
          this.storeAuth.nuvem["selecionarDiretorio"] == null
        ) {
          nuvem = {
            ...this.storeAuth?.sessao.usuario.nuvem,
            usuarioNuvem: this.storeAuth?.sessao.usuario,
          };
          token = this.storeAuth?.sessao.key;
        } else if (
          dadosInstancia.tipo == "remoto" &&
          this.storeAuth.nuvem["selecionarDiretorio"] !== null
        ) {
          nuvem = this.storeAuth.nuvem["selecionarDiretorio"];
          token = this.storeAuth.token["selecionarDiretorio"];
        }
        
        //* Verifica se deve 
        if (dadosInstancia.barraPath !== "/") {

          //* Define os dados do diretorio preenchido
          const bodyMkdir = {
            diretorioPath: dadosInstancia.barraPath,
            criar: true
          };
          
          //* Envia o diretorio para verificar e criar caso nao exista
          const apiRequestMkdir = await api.post(this.storeAuth.apiNuvem("selecionarDiretorio") + "/diretorio/mkdirs", bodyMkdir, this.storeAuth.token["selecionarDiretorio"] !== "" ? this.storeAuth.token["selecionarDiretorio"] : null);

          //* Verifica se retornou um id
          if(apiRequestMkdir.data._id)
          {
            //* Define o id do diretorio
            dadosInstancia.diretorioAtual = apiRequestMkdir.data._id;
          }

				}

        //* Emite os dados do item selecionado
        this.$emit("selecionar", {
          token: token,
          nuvem: nuvem,
          tipo: dadosInstancia.tipo,
          caminho: dadosInstancia.barraPath,
          id: dadosInstancia.diretorioAtual,
        });
      } else if (this.funcao == "selecionar-diretorio-multiplo") {
        //* Emite os dados do item selecionado
        this.$emit("selecionar-multiplo", {});
      }

      //* Fecha a dialog
      this.$refs.dialogDiretorioSelecionar.fechar();
      this.$emit("dialog-off");

    },
    async trocarAba(novaAba) {
      this.aba = novaAba;
    },
  },
};
</script>

<style>
</style>
