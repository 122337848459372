import { defineStore } from "pinia";
import { api } from "@/plugins/api.js";
import { funcoes } from "@/plugins/funcoes.js";

import { useStoreUpload } from "./storeUpload";
import { useAuthStore } from "@/stores/auth.js";

var fs;
var path;

var child;
var os;
var trash;

var shellElectron;

try {
	fs = require("fs");
} catch (errFS) {
	console.log(errFS);
}

try {
	path = require("path");
} catch (errPath) {
	console.log(errPath);
}

try {
	child = require("child_process");
} catch (errChild) {
	console.log(errChild);
}

try {
	os = require("os");
} catch (errOS) {
	console.log(errOS);
}

try {
	trash = require("trash");
} catch (errOS) {
	console.log(errOS);
}

try {
	shellElectron = require("electron").shell;
} catch (errElectron) {
	console.log(errElectron);
}

export const useStoreArvore = defineStore({
	id: "arvore",
	state: () => ({
		instancias: {},
		dialogAberta: false,
		clipboard: { copiar: { instancia: "", tipo: "", itens: [] }, recortar: { instancia: "", itens: [] } },
		instanciaSelecionada: ""
	}),
	actions: {
		async criar(id, tipo) {
			const storeAuth = useAuthStore();

			if (!this.instancias[id]) {
				this.instancias[id] = {
					timerFiltroTexto: null,
					itensTotal: 0,
					filtroTexto: "",
					filtroNome: "",
					diretorioRoot: "",
					diretorioAtual: "",
					diretorioPai: "",
					diretorioAnterior: "",
					barraPath: "",
					barraPathAnterior: "",
					ordem: "nome_ASC",
					itens: [],
					selecionados: {},
					selecionadosIndex: null,
					selecaoMultipla: {},
					diretorioExcluidoRoot: "",
					arquivoAtual: {},
					tipo: tipo,
					view: storeAuth?.sessao.usuario.tipoUsuario == "adminNuvem" || tipo == "local" ? "diretorio" : "compartilhamentos",
					funcao: "padrao",
					barraPathOpcoes: {},
					barraPathOpcoesMostrar: false,
					carregandoItens: true
				};

				//* Carrega os itens da instancia conforme o tipo
				await this.recarregarInstancia(id, tipo);
			}
		},
		async selecionarView(instancia, nova) {
			let viewAtual = this.instancias[instancia].view;

			if (nova == "diretorio") {
				if (viewAtual == "excluidos") {
					this.excluidos(instancia);
				}

				if (viewAtual == "versoes") {
					this.versoes(instancia);
				}

				if (viewAtual == "pesquisa") {
					this.instancias[instancia].view = nova;
				}

				if (viewAtual == "compartilhamentos") {
					this.instancias[instancia].view = nova;
					this.instancias[instancia].diretorioRoot = "";
					this.instancias[instancia].diretorioAtual = "";
					this.instancias[instancia].diretorioPai = "";
					this.instancias[instancia].diretorioAnterior = "";
					this.instancias[instancia].barraPath = "/";
					this.instancias[instancia].barraPathAnterior = "/";					
				}

				this.atualizar(instancia);
			} else if (nova == "compartilhamentos") {
				this.instancias[instancia].view = nova;
				this.instancias[instancia].diretorioRoot = "";
				this.instancias[instancia].diretorioAtual = "";
				this.instancias[instancia].diretorioPai = "";
				this.instancias[instancia].diretorioAnterior = "";
				this.instancias[instancia].barraPath = "/";
				this.instancias[instancia].barraPathAnterior = "/";
				this.atualizar(instancia);
			}
		},
		async recarregarInstanciaAtual(id) {
			this.recarregarInstancia(id, this.instancias[id].tipo);
		},
		async recarregarInstancia(id, tipo, carregar = true) {
			const storeAuth = useAuthStore();

			//* Define o tipo da instancia
			this.instancias[id].tipo = tipo;
			
			//* Verifica o tipo do usuario
			if((tipo == "local") || (storeAuth.nuvem[id] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[id] !== null && storeAuth?.nuvem[id].usuarioNuvem.tipoUsuario == 'adminNuvem'))
			{
				this.instancias[id].view = "diretorio";
			}else{
				this.instancias[id].view = "compartilhamentos";
			}

			this.instancias[id].selecaoMultipla = {};
			this.instancias[id].barraPathOpcoes = {};

			//* Define o id para carregar o historico de opcoes
			var idBarraPathOpcoes = "barraPathOpcoes_" + this.instancias[id].tipo + "_" + (storeAuth.nuvem[id] == null ? storeAuth?.sessao.usuario.nuvem._id : storeAuth?.nuvem[id]._id);

			//* Carrega os itens caso exista
			var barraPathOpcoes = JSON.parse(localStorage.getItem(idBarraPathOpcoes));

			//* Verifica se encontrou salvo um historico
			if (barraPathOpcoes !== null) {
				this.instancias[id].barraPathOpcoes = barraPathOpcoes;
			}

			//* Verifica o tipo da instancia
			if (tipo == "local" && funcoes.appTipo() == "desktop") {
				if (process.platform === "win32") {
					this.instancias[id].diretorioRoot = "";
					this.instancias[id].diretorioAtual = this.instancias[id].diretorioRoot;
					this.instancias[id].diretorioAnterior = this.instancias[id].diretorioRoot;
				} else {
					this.instancias[id].diretorioRoot = "/";
					this.instancias[id].diretorioAtual = storeAuth.diretorioLocalUltimo == "" ? this.instancias[id].diretorioRoot : storeAuth.diretorioLocalUltimo;
					this.instancias[id].diretorioAnterior = this.instancias[id].diretorioAtual;
				}

				this.instancias[id].diretorioPai = path.resolve(this.instancias[id].diretorioAtual, "..");

				this.instancias[id].barraPath = this.instancias[id].diretorioAtual;
				this.instancias[id].barraPathAnterior = this.instancias[id].diretorioAtual;

				if (carregar) {
					this.carregarItensLocal(id);
				}
			} else {
				this.instancias[id].diretorioRoot = "";
				this.instancias[id].diretorioAtual = "";
				this.instancias[id].diretorioPai = "";
				this.instancias[id].diretorioAnterior = "";
				this.instancias[id].barraPath = "/";
				this.instancias[id].barraPathAnterior = "/";

				if (carregar) {
					this.carregarItens(id);
				}
			}
		},
		insereBarraPathOpcoes(instancia, item) {
			const storeAuth = useAuthStore();

			if (this.instancias[instancia].barraPathOpcoes[item.id]) {
				return;
			}

			//* Carrega os itens atuais
			var arrKeys = Object.keys(this.instancias[instancia].barraPathOpcoes).reverse();

			//* Percorre os itens do historico excedentes
			for (var indice = 14; indice < arrKeys.length; indice++) {
				//* Remove o item
				delete this.instancias[instancia].barraPathOpcoes[arrKeys[indice]];
			}

			//* Insere o item
			this.instancias[instancia].barraPathOpcoes[item.id] = item;

			//* Define o id para carregar o historico de opcoes
			var idBarraPathOpcoes = "barraPathOpcoes_" + this.instancias[instancia].tipo + "_" + (storeAuth.nuvem[instancia] == null ? storeAuth?.sessao.usuario.nuvem._id : storeAuth?.nuvem[instancia]._id);

			//* Salva os path acessados
			localStorage.setItem(idBarraPathOpcoes, JSON.stringify(this.instancias[instancia].barraPathOpcoes));
		},
		async carregarItens(instancia, limparSelecionados = true) {
			const storeAuth = useAuthStore();

			//* Limpa os itens selecionados
			if (limparSelecionados) {
				this.instancias[instancia].selecionados = {};
				this.instancias[instancia].selecionadosIndex = null;
			}

			let filtros = "";

			//* Define o diretorio e o path a ser carregado
			filtros += "?diretorio=" + this.instancias[instancia].diretorioAtual;
			filtros += "&path=" + encodeURIComponent(this.instancias[instancia].barraPath);

			if (this.instancias[instancia].view == "excluidos") {
				filtros += "&excluidos=true";
			}

			if (this.instancias[instancia].view == "pesquisa") {
				if (this.filtroNome !== "") {
					filtros += "&filtroNome=" + this.instancias[instancia].filtroNome;
				}
			}

			if (this.filtroTexto !== "") {
				filtros += "&_all=" + this.instancias[instancia].filtroTexto;
			}

			filtros += "&_ordem=" + this.instancias[instancia].ordem;

			if (this.instancias[instancia].funcao == "selecionar-diretorio") {
				filtros += "&somenteDiretorios=true";
			}

			if (this.instancias[instancia].view == "compartilhamentos") {
				filtros += "&compartilhamento";
			}

			var arrItensHeader = [];
			var idAnterior = "";

			//* Verifica se esta visualizando um diretorio ou os itens excluidos
			if (this.instancias[instancia].view == "diretorio" || this.instancias[instancia].view == "compartilhamentos" || this.instancias[instancia].view == "pesquisa" || this.instancias[instancia].view == "excluidos") {
				//* Define o status
				this.instancias[instancia].carregandoItens = true;

				//* Carrega os dados do diretorio
				const response = await api.get(storeAuth.apiNuvem(instancia) + "/diretorio/list" + filtros, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				api.valid(response.data, this.$router);

				if (response.data.erro == false) {
					//* Verifica se consta um diretorio pai, se esta apresentando um diretorio ou itens excluidos nao sendo o diretorio root dos excluidos
					if (response.data.diretorio._id && (this.instancias[instancia].view == "diretorio" || this.instancias[instancia].view == "compartilhamentos" || this.instancias[instancia].view == "pesquisa" || (this.instancias[instancia].view == "excluidos" && this.instancias[instancia].diretorioExcluidoRoot !== this.instancias[instancia].diretorioAtual))) {

						//* DREPRECATED: Deve sair apos base migrar para fullpath, mantendo codigo a baixo
						idAnterior = response.data.diretorio.diretorioPai ? response.data.diretorio.diretorioPai : "";

						//* DEPRECATED: manter somente o codigo a baixo futuramente
						if(idAnterior == "")
						{
							//* Define o id do pai
							idAnterior = response.data.diretorio.diretorioPaiDados ? response.data.diretorio.diretorioPaiDados._id : "";
						}

						//* Registra o diretorio pai
						this.instancias[instancia].diretorioPai = idAnterior;

						//* Caso esteja no compartilhamento, verifica se chegou no diretorio filho da raiz
						if (this.instancias[instancia].view == "compartilhamentos" && response.data.diretorio.compartilhamento.diretorio._id == response.data.diretorio._id) {
							//* Limpa o id anterior, para retornar para o diretorio ROOT
							idAnterior = "";
						}
						
						//* Verifica se consta um diretorio pai
						if (idAnterior !== "" && response.data.diretorio.diretorioPaiDados) {
							//* Insere o diretorio pai para retornar
							arrItensHeader.push({ ...response.data.diretorio.diretorioPaiDados, isDiretorioPai: true, nome: "... ", dataAlteracao:null });
						//* Verifica se esta no ultimo nivel dos itens excluidos
						}else if(!response.data.diretorio.diretorioPaiDados && response.data.diretorio.diretorioPaiFullPath && response.data.diretorio.diretorioPaiFullPath !== null)
						{
							arrItensHeader.push({ _id: this.instancias[instancia].diretorioExcluidoRoot, fullPath: response.data.diretorio.diretorioPaiFullPath, isDiretorioPai: true, nome: "... ", dataAlteracao:null });
						} else {
							arrItensHeader.push({
								_id: idAnterior,
								nome: "... ",
								fullPath: "/",
								isArquivo: false,
								isDriver: false,
								isDiretorioPai: true
							});
						}
					}

					//* Atualiza o path para o correto
					//this.instancias[instancia].barraPath = response.data.diretorio.fullPath ? response.data.diretorio.fullPath : "/";

					//* Passa os itens encontrados
					this.instancias[instancia].itens = arrItensHeader.concat(response.data.itens);
					this.instancias[instancia].itensTotal = response.data.total;
				}

				//* Define o status
				this.instancias[instancia].carregandoItens = false;
			} else {
				//* Define o status
				this.instancias[instancia].carregandoItens = true;

				const response = await api.get(storeAuth.apiNuvem(instancia) + "/arquivo/" + this.instancias[instancia].arquivoAtual._id, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				api.valid(response.data, this.$router);

				idAnterior = "";

				var itens = [];

				Object.keys(response.data.itens[0].versoes).forEach((versaoId, index) => {
					itens.push({
						_id: response.data.itens[0]._id + "_" + versaoId,
						id: versaoId,
						key: response.data.itens[0].versoes[versaoId].key,
						nome: response.data.itens[0].versoes[versaoId].nome,
						tipo: response.data.itens[0].versoes[versaoId].extensao,
						data: response.data.itens[0].versoes[versaoId].data,
						statusTransferencia: response.data.itens[0].versoes[versaoId].statusTransferencia,
						dataAlteracao: response.data.itens[0].versoes[versaoId].dataAlteracao ? response.data.itens[0].versoes[versaoId].dataAlteracao : response.data.itens[0].versoes[versaoId].data,
						tamanho: response.data.itens[0].versoes[versaoId].tamanho,
						versao: index + 1,
						fullPath: "/",
						diretorioPath: this.instancias[instancia].barraPath,
						isArquivo: true,
						isDiretorio: false,
						isDriver: false
					});
				});

				this.instancias[instancia].itens = arrItensHeader.concat(itens.reverse());
				this.instancias[instancia].itensTotal = this.instancias[instancia].itens.length;

				//* Define o status
				this.instancias[instancia].carregandoItens = false;
			}
		},
		async carregarItensLocal(instancia) {
			const storeAuth = useAuthStore();

			try {
				var thisCall = this;

				//* Limpa os itens selecionados
				this.instancias[instancia].selecionados = {};
				this.instancias[instancia].selecionadosIndex = null;

				//* Salva o ultimo diretorio aberto
				storeAuth.alterarDiretorioLocalUltimo(this.instancias[instancia].diretorioAtual);

				//* Verifica se nao existe o caminho
				if (this.instancias[instancia].diretorioAtual !== "" && !fs.existsSync(this.instancias[instancia].diretorioAtual)) {
					//* Reinicia o ultimo diretorio
					storeAuth.alterarDiretorioLocalUltimo("");

					//* Define para recarregar a instancia
					this.recarregarInstancia(instancia, "local");
				}

				//* Define o status
				this.instancias[instancia].carregandoItens = true;

				//* Verifica se deve carregar as unidades windows
				if (this.instancias[instancia].diretorioAtual == "") {
					//* Percorre os discos
					child.exec("wmic logicaldisk get name", (error, stdout) => {
						var arrItensRaiz = [];

						//* Define o path do desktop
						var desktopPath = os.homedir() + "\\Desktop";

						//* Define o item desktop
						arrItensRaiz.push({
							_id: desktopPath,
							nome: "Desktop",
							fullPath: desktopPath,
							isArquivo: false,
							isDriver: true
						});

						//* Carrega as unidades
						stdout
							.split("\r\r\n")
							.filter((value) => /[A-Za-z]:/.test(value))
							.map((value) => {
								//* Acerta o path da unidade
								value = value.trim() + "\\";

								//* Insere a unidade na lista
								arrItensRaiz.push({
									_id: value,
									nome: value,
									fullPath: value,
									isArquivo: false,
									isDriver: true
								});
							});

						thisCall.instancias[instancia].itens = arrItensRaiz;
						thisCall.instancias[instancia].itensTotal = arrItensRaiz.length;
					});

					//* Define o status
					thisCall.instancias[instancia].carregandoItens = false;

					return;
				}

				var arrItensHeader = [];
				var arrItens = [];

				fs.readdir(this.instancias[instancia].diretorioAtual, (err, files) => {
					if (thisCall.instancias[instancia].diretorioAtual !== this.instancias[instancia].diretorioRoot) {
						//* Desmembra o path
						var arrPath = thisCall.instancias[instancia].diretorioAtual.split("\\");

						//* Verifica se esta carregando a raiz de um driver windows
						if (this.instancias[instancia].diretorioRoot == "" && arrPath.length == 2 && arrPath[1].trim() == "") {
							//* Insere o retorno para a lista de drivers
							arrItensHeader.push({
								_id: "",
								nome: "... ",
								fullPath: "",
								isArquivo: false,
								isDriver: false,
								isDiretorioPai: true
							});
						} else {
							//* Carrega o path do diretorio anterior
							var diretorioPai = path.resolve(thisCall.instancias[instancia].diretorioAtual, "..");

							//* Insere na lista
							arrItensHeader.push({
								_id: diretorioPai,
								nome: "... ",
								fullPath: diretorioPai,
								isArquivo: false,
								isDriver: false,
								isDiretorioPai: true
							});
						}
					}

					for (var indiceItem = 0; indiceItem < files.length; indiceItem++) {
						try {
							var item = files[indiceItem];
							var itemStats = fs.lstatSync(path.resolve(this.instancias[instancia].diretorioAtual, item));

							const extname = path.extname(item);
							var filename = "";
							const absolutePath = path.resolve(this.instancias[instancia].diretorioAtual, item);
							var inserirItem = true;

							if (!itemStats.isFile()) {
								filename = path.basename(item);

								if (this.instancias[instancia].view == "pesquisa" && this.instancias[instancia].filtroNome !== "") {
									if (filename.toLocaleLowerCase().match(new RegExp(this.instancias[instancia].filtroNome.toLocaleLowerCase())) == null) {
										inserirItem = false;
									}
								}

								//* Verifica se deve inserir o item
								if (inserirItem) {
									arrItensHeader.push({
										_id: absolutePath,
										nome: filename,
										fullPath: absolutePath,
										diretorioPath: this.instancias[instancia].diretorioAtual,
										isArquivo: false,
										isDriver: false
									});
								}
							} else {
								//* Verifica se deve apresentar arquivos
								if (this.instancias[instancia].funcao == "padrao") {
									filename = path.basename(item, extname);

									if (this.instancias[instancia].view == "pesquisa" && this.instancias[instancia].filtroNome !== "") {
										if (filename.toLocaleLowerCase().match(new RegExp(this.instancias[instancia].filtroNome.toLocaleLowerCase())) == null) {
											inserirItem = false;
										}
									}

									if (inserirItem) {
										arrItens.push({
											_id: absolutePath,
											nome: filename,
											fullPath: absolutePath,
											tipo: extname == "" ? " " : extname,
											tamanho: itemStats.size,
											data: itemStats.mtime,
											diretorioPath: this.instancias[instancia].diretorioAtual,
											isArquivo: true,
											isDriver: false
										});
									}
								}
							}
						} catch (e) {
							console.log(e);
						}
					}

					thisCall.instancias[instancia].itens = arrItensHeader.concat(arrItens);
					thisCall.instancias[instancia].itensTotal = arrItens.length;

					//* Define o status
					thisCall.instancias[instancia].carregandoItens = false;
				});
			} catch (errPrincipal) {
				console.log(errPrincipal);
			}
		},
		navegarArvore(instancia, item) {
			if (item.status) {
				return;
			}

			if (!item.isArquivo) {
				this.diretorioAbrir(instancia, item);
			} else {
				//* Define para transferir o arquivo clicado
				this.transferir(instancia, [item]);
			}
		},
		diretorioAbrir(instancia, item) {
			if (this.instancias[instancia].tipo == "local") {
				this.instancias[instancia].diretorioAnterior = this.instancias[instancia].diretorioAtual;
				this.instancias[instancia].diretorioPai = this.instancias[instancia].diretorioAtual;
				this.instancias[instancia].diretorioAtual = item.fullPath;
				this.instancias[instancia].barraPath = item.fullPath;
				this.instancias[instancia].barraPathAnterior = item.fullPath;
				this.instancias[instancia].diretorioPai = path.resolve(this.instancias[instancia].diretorioAtual, "..");

				this.carregarItensLocal(instancia);
			} else {

				this.instancias[instancia].diretorioAnterior = this.instancias[instancia].diretorioAtual;
				this.instancias[instancia].diretorioPai = this.instancias[instancia].diretorioAtual;
				this.instancias[instancia].diretorioAtual = item._id;

				//* Verifica se deve ajustar o path pelo compartilhamento
				if (item.compartilhamento) {
					//* Define o path do compartilhamento
					let fullPathCompartilhamento = "/" + item.compartilhamento.nome;

					//* Verifica se nao selecionou a raiz do compartilhamento
					if (item._id !== item.compartilhamento.diretorio._id) {
						//* Define o restante do path
						fullPathCompartilhamento += item.fullPath.substring(item.compartilhamento.diretorio.fullPath.length);
					}

					//* Define o caminho do diretorio
					this.instancias[instancia].barraPath = fullPathCompartilhamento;
					this.instancias[instancia].barraPathAnterior = fullPathCompartilhamento;
				} else {
					//* Define o caminho do diretorio
					this.instancias[instancia].barraPath = item.fullPath;
					this.instancias[instancia].barraPathAnterior = item.fullPath;
				}

				//* Ajusta as barras
				this.instancias[instancia].barraPath = this.instancias[instancia].barraPath.replaceAll("\\", "/");
				this.instancias[instancia].barraPathAnterior = this.instancias[instancia].barraPath;

				//* Carrega os itens do diretorio
				this.carregarItens(instancia);
			}
		},
		async diretorioIr(instancia, path, _id = "", insereBarraPathOpcoes = false) {
			const storeAuth = useAuthStore();

			//* Verifica se deve inserir o item
			if (insereBarraPathOpcoes) {
				//* Insere o item na lista de opcoes
				this.insereBarraPathOpcoes(instancia, { id: path, path: path });
			}

			//* Verifica o tipo da arvore
			if (this.instancias[instancia].tipo == "local") {
				//* Abre o diretorio
				this.diretorioAbrir(instancia, { fullPath: path });
			} else {
				//* Verifica se vai carregar a raiz
				if (path == "/" || path == "") {
					//* Armazena o diretorio anterior, e posiciona na raiz da arvore
					this.instancias[instancia].diretorioAnterior = this.instancias[instancia].diretorioAtual;
					this.instancias[instancia].diretorioAtual = "";
					this.instancias[instancia].diretorioPai = "";
					this.instancias[instancia].barraPath = "/";
					this.instancias[instancia].barraPathAnterior = "/";
					this.atualizar(instancia);
				} else {
					var filtro = "";

					//* Verifica qual o diretorio a partir do path
					if (_id !== "") {
						filtro = "/" + _id;
					} else {
						if (this.instancias[instancia].view == "compartilhamentos") {
							filtro = "?excluidos=false&fullPathCompartilhamento=" + encodeURIComponent(path);
						} else {
							filtro = "?fullPath=" + encodeURIComponent(path);
						}
					}

					//* Define o diretorio que deve ir
					var diretorioIr = await api.get(storeAuth.apiNuvem(instancia) + "/diretorio" + filtro, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);

					//* Verifica se encontrou o diretorio
					if (diretorioIr.data.erro == false && diretorioIr.data.itens.length > 0) {
						this.diretorioAbrir(instancia, diretorioIr.data.itens[0]);
					}
				}
			}
		},
		diretorioCriar(instancia) {
			var index = 0;

			if (this.instancias[instancia].itens.length > 0) {
				if (this.instancias[instancia].itens[0].nome == "... ") {
					index = 1;
				}
			}

			this.instancias[instancia].itens.splice(index, 0, {
				_id: "",
				nome: "",
				fullPath: "",
				status: "diretorio-novo",
				isArquivo: false,
				erroMensagem: ""
			});
			//this.instancias[instancia].itens.unshift();
		},
		async diretorioSalvar(instancia, index, item) {
			const storeAuth = useAuthStore();

			//* Limpa a mensagem de erro
			this.instancias[instancia].itens[index].erroMensagem = "";

			if (this.instancias[instancia].tipo == "local") {

				//* Define o caminho de destino
				var pathNovoDiretorio = path.resolve(this.instancias[instancia].diretorioAtual, item.nome);
				
				//* Verifica se nao existe um diretorio com o mesmo nome
				if (!fs.existsSync(pathNovoDiretorio)) {
					//* Cria o diretorio local
					fs.mkdirSync(pathNovoDiretorio, { recursive: true });

					//* Define o id
					this.instancias[instancia].itens[index]._id = pathNovoDiretorio;
					this.instancias[instancia].itens[index].fullPath = pathNovoDiretorio;

					//* Limpa o item
					this.instancias[instancia].itens[index].erroMensagem = "";

					//* Apaga os campos de validacao
					delete this.instancias[instancia].itens[index].status;
					delete this.instancias[instancia].itens[index].erroMensagem;
				} else {
					//* Define a mensagem de erro
					this.instancias[instancia].itens[index].erroMensagem = "Diretório já existe";
					return;
				}
			} else {
				let apiRequest = null;

				const body = {
					_id: item._id,
					nome: item.nome,
					diretorioPaiFullPath: this.instancias[instancia].barraPath,
					diretorioPai: this.instancias[instancia].diretorioAtual
				};

				if (item._id === "") {
					apiRequest = await api.post(storeAuth.apiNuvem(instancia) + "/diretorio", body, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				} else {
					apiRequest = await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/" + item._id, body, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				}

				if (apiRequest.data.erro) {
					//* Define a mensagem de erro
					this.instancias[instancia].itens[index].erroMensagem = apiRequest.data.mensagem;
					return;
				} else if (item._id === "") {
					//* Limpa o item
					this.instancias[instancia].itens[index]._id = apiRequest.data._id;
					this.instancias[instancia].itens[index].fullPath = (this.instancias[instancia].barraPath == "/" ? this.instancias[instancia].barraPath : this.instancias[instancia].barraPath + "/") + item.nome;
					this.instancias[instancia].itens[index].diretorioPaiFullPath = this.instancias[instancia].barraPath == "/" ? null : this.instancias[instancia].barraPath;
					delete this.instancias[instancia].itens[index].status;
					delete this.instancias[instancia].itens[index].erroMensagem;
				}
			}
		},
		async diretorioCancelar(instancia, indexItem) {
			this.instancias[instancia].itens = this.instancias[instancia].itens.filter(function (value, index) {
				return index != indexItem;
			});
		},
		atualizar(instancia) {
			const storeAuth = useAuthStore();

			//* Define para atualizar os dados da sessao
			storeAuth.atualizarSessao(instancia);

			if (this.instancias[instancia].tipo == "local") {
				this.carregarItensLocal(instancia);
			} else {
				this.carregarItens(instancia);
			}
		},
		renomear(instancia) {
			//* Percorre todos os itens da arvore
			for (var indexItem = 0; indexItem < this.instancias[instancia].itens.length; indexItem++) {
				//* Verifica se o id consta no array o id selecionado
				if (this.instancias[instancia].selecionados[this.instancias[instancia].itens[indexItem]._id]) {
					//* Define que vai renomear o item
					this.instancias[instancia].itens[indexItem].erroMensagem = "";
					this.instancias[instancia].itens[indexItem].nomeOriginal = this.instancias[instancia].itens[indexItem].nome;
					this.instancias[instancia].itens[indexItem].status = "renomear";
				}
			}
		},
		copiar(instancia, clipboard = true) {
			var arrResult = [];

			//* Verifica se esta definindo no clipboard
			if (clipboard) {
				//* Limpar area transferencia
				this.clipboard.copiar.instancia = instancia;
				this.clipboard.copiar.tipo = this.instancias[instancia].view == "versoes" ? "versoes" : this.instancias[instancia].view == "excluidos" ? "excluidos" : "arquivos";
				this.clipboard.copiar.itens.length = 0;

				this.clipboard.recortar.instancia = "";
				this.clipboard.recortar.itens.length = 0;
			}

			//* Percorre todos os itens da arvore
			for (var indexItem = 0; indexItem < this.instancias[instancia].itens.length; indexItem++) {
				//* Verifica se o id consta no array o id selecionado
				if (this.instancias[instancia].selecionados[this.instancias[instancia].itens[indexItem]._id]) {
					//* Define o diretorio do item
					this.instancias[instancia].itens[indexItem].diretorioPath = this.instancias[instancia].barraPath;

					//* Verifica se deve colocar no clipboard
					if (clipboard) {
						this.clipboard.copiar.itens.push(this.instancias[instancia].itens[indexItem]);
					} else {
						arrResult.push(this.instancias[instancia].itens[indexItem]);
					}
				}
			}

			//* Verifica se deve retornar os itens
			if (clipboard == false) {
				return arrResult;
			}
		},
		recortar(instancia) {
			//* Limpar area transferencia
			this.clipboard.copiar.instancia = "";
			this.clipboard.copiar.tipo = "";
			this.clipboard.copiar.itens.length = 0;

			this.clipboard.recortar.instancia = instancia;
			this.clipboard.recortar.itens.length = 0;

			//* Percorre todos os itens da arvore
			for (var indexItem = 0; indexItem < this.instancias[instancia].itens.length; indexItem++) {
				//* Verifica se o id consta no array o id selecionado
				if (this.instancias[instancia].selecionados[this.instancias[instancia].itens[indexItem]._id]) {
					//* Define o diretorio do item
					this.instancias[instancia].itens[indexItem].diretorioPath = this.instancias[instancia].barraPath;

					this.clipboard.recortar.itens.push(this.instancias[instancia].itens[indexItem]);
				}
			}
		},
		async versoes(instancia) {
			//* Troca o tipo de visualizacao
			this.instancias[instancia].view = this.instancias[instancia].view == "versoes" ? "diretorio" : "versoes";

			//* Verifica se deve filtrar as versoes
			if (this.instancias[instancia].view == "versoes") {
				//* Define o arquivo selecionado para listar as versoes
				this.instancias[instancia].arquivoAtual = this.instancias[instancia].selecionados[Object.keys(this.instancias[instancia].selecionados)[0]];

				//* Carrega os itens com a selecao ja definida
				this.carregarItens(instancia, true);
			} else {
				//* Restaura o item selecionado
				this.instancias[instancia].selecionados = {};
				this.instancias[instancia].selecionadosIndex = null;
				//this.instancias[instancia].selecionados[this.instancias[instancia].arquivoAtual._id] = this.instancias[instancia].arquivoAtual;

				//* Limpa o arquivo filtrado
				this.instancias[instancia].arquivoAtual = {};

				//* Carrega os itens com a selecao ja definida
				this.carregarItens(instancia, false);
			}
		},
		async excluidos(instancia) {
			//* Troca o tipo de visualizacao
			this.instancias[instancia].view = this.instancias[instancia].view == "excluidos" ? "diretorio" : "excluidos";

			//* Verifica se esta selecionando um item excluidos
			if (this.instancias[instancia].view == "excluidos") {
				//* Armazena o item root atual
				this.instancias[instancia].diretorioExcluidoRoot = this.instancias[instancia].diretorioAtual;
			} else {
				this.instancias[instancia].diretorioExcluidoRoot = "";
			}

			//* Define para carregar os itens
			this.carregarItens(instancia, true);

			//* Verifica se deve filtrar as versoes
			if (this.instancias[instancia].view == "excluidos") {
				//* Carrega os itens com a selecao ja definida
				this.carregarItens(instancia, true);
			} else {
				//* Carrega os itens com a selecao ja definida
				this.carregarItens(instancia, true);
			}
		},
		async colar(instancia) {
			const storeUpload = useStoreUpload();
			const storeAuth = useAuthStore();

			var arrItensDiretorios = [];
			var arrItensArquivos = [];

			var filaId = null;

			//* Verifica se consta itens a copiar
			if (this.clipboard.copiar.itens.length > 0) {
				var indexCopiar = 0;
				var diretorioIdPrincipal = null;
				//var manterDiretorioPrincipal = false;

				var novoCaminhoRecortar = null;

				//* Verifica se o destino e local
				if (this.instancias[this.clipboard.copiar.instancia].tipo == "remoto" && this.instancias[instancia].tipo == "local") {
					//* Verifica se nao selecionou nenhu item
					if (this.clipboard.copiar.itens.length == 0) {
						return;
					}

					//* Cria a fila de transferencia
					filaId = storeUpload.criarFila("download", storeAuth.apiNuvem(this.clipboard.copiar.instancia), storeAuth.token[this.clipboard.copiar.instancia] !== "" ? storeAuth.token[this.clipboard.copiar.instancia] : null);

					//* Define o id do diretorio principal
					diretorioIdPrincipal = this.clipboard.copiar.itens[indexCopiar].diretorioPath;

					//* Insere o diretorio principal para ficar no topo da lista
					storeUpload.criarFilaDiretorio(filaId, diretorioIdPrincipal, null, null, this.instancias[this.clipboard.copiar.instancia].barraPath, this.instancias[instancia].tipo, false, { caminhoParent: this.instancias[instancia].barraPath, diretorioParent: "" });

					//* Insere nos subdiretorios esse diretorio principal
					storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal] = true;

					//* Percorre todos os itens da area de transferencia
					for (indexCopiar = 0; indexCopiar < this.clipboard.copiar.itens.length; indexCopiar++) {
						var itemCopiarRL = this.clipboard.copiar.itens[indexCopiar];

						//* Verifica se esta colando um arquivo
						if (this.clipboard.copiar.itens[indexCopiar].isArquivo) {
							await storeUpload.downloadArquivo(filaId, itemCopiarRL, this.instancias[instancia].barraPath, itemCopiarRL.diretorioPath);
						} else {
							//* Cria o diretorio na fila como download
							storeUpload.criarFilaDiretorio(filaId, itemCopiarRL._id, null, null, itemCopiarRL.fullPath, this.instancias[this.clipboard.copiar.instancia].tipo, true, { caminhoParent: this.instancias[instancia].barraPath, diretorioParent: "" });

							//* Insere o diretorio na lista principal
							storeUpload.fila[filaId].subdiretorios[itemCopiarRL._id] = true;

							//* Manda executar o proximo arquivo
							storeUpload.filasProximoArquivoTimer(1500);
						}
					}

					/*
						Desabilitado
					//* Verifica se nao foi inserido nenhum arquivo no diretorio principal
					if (storeUpload.fila[filaId].diretorios[diretorioIdPrincipal].arquivos.length == 0) {
						//* Remove o diretorio principal caso nenhum arquivo selecionado para ele
						delete storeUpload.fila[filaId].diretorios[diretorioIdPrincipal];
						delete storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal];
					}*/
				} else if (this.instancias[this.clipboard.copiar.instancia].tipo == "local" && this.instancias[instancia].tipo == "remoto") {
					//* Verifica se consta itens selecionados
					if (this.clipboard.copiar.itens.length == 0) {
						return;
					}

					//* Cria a fila de transferencia
					filaId = storeUpload.criarFila("upload", storeAuth.apiNuvem(instancia), storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);

					//* Define o id do diretorio principal
					diretorioIdPrincipal = this.clipboard.copiar.itens[0].diretorioPath;

					//* Insere o diretorio principal para ficar no topo da lista
					storeUpload.criarFilaDiretorio(filaId, diretorioIdPrincipal, null, null, this.instancias[this.clipboard.copiar.instancia].barraPath, this.instancias[this.clipboard.copiar.instancia].tipo, false, { caminhoParent: this.instancias[instancia].barraPath, diretorioParent: this.instancias[instancia].diretorioAtual });

					//* Insere nos subdiretorios esse diretorio principal
					storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal] = true;

					//* Percorre todos os itens da area de transferencia
					for (indexCopiar = 0; indexCopiar < this.clipboard.copiar.itens.length; indexCopiar++) {
						var item = this.clipboard.copiar.itens[indexCopiar];

						//* Verifica se esta colando um arquivo
						if (item.isArquivo) {
							//* Verifica se esta inserindo no diretorio principal
							if (path.dirname(item.fullPath) == diretorioIdPrincipal) {
								//* Define para manter o diretorio principal inserido
								//manterDiretorioPrincipal = true;
							}

							storeUpload.uploadArquivo(filaId, item.nome + item.tipo, item.fullPath, this.instancias[instancia].diretorioAtual, this.instancias[instancia].barraPath);
						} else {
							//* Cria o diretorio na fila como upload
							storeUpload.criarFilaDiretorio(filaId, item.fullPath, null, null, item.fullPath, this.instancias[this.clipboard.copiar.instancia].tipo, true, { caminhoParent: this.instancias[instancia].barraPath, diretorioParent: this.instancias[instancia].diretorioAtual });

							//* Insere o diretorio na lista principal
							storeUpload.fila[filaId].subdiretorios[item.fullPath] = true;

							//* Manda executar o proximo arquivo
							storeUpload.filasProximoArquivoTimer(1500);
						}
					}

					/*
						Desabilitado
					//* Verifica se nao foi inserido nenhum arquivo no diretorio principal
					if (!manterDiretorioPrincipal) {
						//* Remove o diretorio principal caso nenhum arquivo selecionado para ele
						delete storeUpload.fila[filaId].diretorios[diretorioIdPrincipal];
						delete storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal];
					}*/
				} else if (this.instancias[this.clipboard.copiar.instancia].tipo == "local" && this.instancias[instancia].tipo == "local") {
					//* Cola da arvore local para arvore local
					await this.colarLocalLocal(instancia);
				} else if (this.instancias[this.clipboard.copiar.instancia].tipo == "remoto" && this.instancias[instancia].tipo == "remoto") {
					//* Colar da arvore remota para arvore remota
					await this.colarRemotoRemoto(false, instancia);
				}
			}

			//* Verifica se consta itens a recortar
			if (this.clipboard.recortar.itens.length > 0) {
				//* Verifica se as instancias sao remotas
				if (this.instancias[instancia].tipo == "remoto" && this.instancias[this.clipboard.recortar.instancia].tipo == "remoto") {
					if (instancia == this.clipboard.recortar.instancia) {
						//* Percorre todos os itens da area de transferencia
						for (var indexItemRecortar = 0; indexItemRecortar < this.clipboard.recortar.itens.length; indexItemRecortar++) {
							var itemRecortar = this.clipboard.recortar.itens[indexItemRecortar];

							if (itemRecortar.isDiretorio == true) {
								arrItensDiretorios.push(itemRecortar._id);
							} else if (itemRecortar.isArquivo == true) {
								arrItensArquivos.push(itemRecortar._id);
							}
						}

						//* Movimenta os arquivos de diretorio
						await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/mover", { diretorioPara: this.instancias[instancia].diretorioAtual, itensDiretorios: arrItensDiretorios, itensArquivos: arrItensArquivos }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
					} else {
						//* Colar da arvore remota para arvore remota
						await this.colarRemotoRemoto(true, instancia, this.clipboard.recortar.itens, "arquivos");
					}
				} else if (this.instancias[instancia].tipo == "local" && this.instancias[this.clipboard.recortar.instancia].tipo == "local") {
					//* Percorre todos os itens da area de transferencia
					for (var indexItemRecortarLocal = 0; indexItemRecortarLocal < this.clipboard.recortar.itens.length; indexItemRecortarLocal++) {
						//* Instancia o item a ser movido
						var itemRecortarLocal = this.clipboard.recortar.itens[indexItemRecortarLocal];

						//* Define o novo caminho
						novoCaminhoRecortar = null;

						//* Verifica se e um arquivo
						if (itemRecortarLocal.isArquivo) {
							//* Define o novo caminho do arquivo
							novoCaminhoRecortar = path.resolve(this.instancias[instancia].barraPath, itemRecortarLocal.nome + itemRecortarLocal.tipo);
						} else {
							//* Define o novo caminho do diretorio
							novoCaminhoRecortar = path.resolve(this.instancias[instancia].barraPath, itemRecortarLocal.nome);
						}

						//* Move o conteudo
						fs.renameSync(itemRecortarLocal._id, novoCaminhoRecortar);
					}
				}
			}

			//* Verifica se foi criada alguma fila
			if (filaId !== null) {
				//* Define que finalizou de carregar esta fila
				storeUpload.fila[filaId].carregando = false;
			}

			//* Limpar area transferencia
			this.clipboard.copiar.instancia = "";
			this.clipboard.copiar.tipo = "";
			this.clipboard.copiar.itens.length = 0;

			this.clipboard.recortar.instancia = instancia;
			this.clipboard.recortar.itens.length = 0;

			//* Atualiza a arvore
			this.atualizar(instancia);
		},
		async colarLocalLocal(instancia, itens = null) {
			const storeUpload = useStoreUpload();

			var itemCopiar = null;
			var filaId = null;

			var diretorioIdPrincipal = null;
			var novoCaminhoCopiar = null;

			var itensCopiar;

			//* Verifica se deve carregar os itens da area de transferencia
			if (itens == null) {
				itensCopiar = this.clipboard.copiar.itens;
			} else {
				itensCopiar = itens;
			}

			//* Percorre todos os itens da area de transferencia
			for (var indexCopiar = 0; indexCopiar < itensCopiar.length; indexCopiar++) {
				//* Instancia o item a ser copiado
				itemCopiar = itensCopiar[indexCopiar];

				//* Verifica se ja criou a fila
				if (filaId == null) {
					//* Cria a fila de transferencia
					filaId = storeUpload.criarFila("copia");

					//* Define o id do diretorio principal
					diretorioIdPrincipal = itemCopiar.diretorioPath;

					//* Insere o diretorio principal para ficar no topo da lista
					storeUpload.criarFilaDiretorio(filaId, diretorioIdPrincipal, null, null, diretorioIdPrincipal, "local");

					//* Insere nos subdiretorios esse diretorio principal
					storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal] = true;
				}

				//* Define o novo caminho
				novoCaminhoCopiar = null;

				//* Verifica se e um arquivo
				if (itemCopiar.isArquivo) {
					//* Define o novo caminho do arquivo
					novoCaminhoCopiar = path.resolve(this.instancias[instancia].barraPath, itemCopiar.nome + itemCopiar.tipo);

					//* Copia o arquivo local
					await storeUpload.copiaArquivoLocal(filaId, itemCopiar._id, novoCaminhoCopiar);
				} else {
					//* Define o novo caminho do diretorio
					novoCaminhoCopiar = path.resolve(this.instancias[instancia].barraPath, itemCopiar.nome);

					//* Cria o diretorio na fila de transferencia
					storeUpload.criarFilaDiretorio(filaId, itemCopiar._id, null, null, itemCopiar._id, "local", true, { caminhoParent: this.instancias[instancia].barraPath, diretorioParent: "" });

					//* Insere o diretorio na lista principal
					storeUpload.fila[filaId].subdiretorios[itemCopiar._id] = true;
				}
			}

			//* Verifica se foi criada alguma fila
			if (filaId !== null) {
				//* Define que finalizou de carregar esta fila
				storeUpload.fila[filaId].carregando = false;
			}
		},
		async colarRemotoRemoto(mover, instancia, itens = null, tipo = null) {
			const storeAuth = useAuthStore();

			var itensCopiar;

			var arrItensDiretorios = [];
			var arrItensArquivos = [];

			var itemCopiar = null;

			//* Verifica se deve carregar os itens da area de transferencia
			if (itens == null) {
				itensCopiar = this.clipboard.copiar.itens;
				tipo = this.clipboard.copiar.tipo;
			} else {
				itensCopiar = itens;
			}

			//* Percorre todos os itens da area de transferencia
			for (var indexItemCopiar = 0; indexItemCopiar < itensCopiar.length; indexItemCopiar++) {
				itemCopiar = itensCopiar[indexItemCopiar];

				if (itemCopiar.isDiretorio == true) {
					arrItensDiretorios.push(itemCopiar._id);
				} else if (itemCopiar.isArquivo == true) {
					arrItensArquivos.push(itemCopiar._id);
				}
			}

			//* Verifica o tipo de copia
			if (tipo == "arquivos" || tipo == "excluidos") {
				//* Copia os arquivos para o diretorio
				await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/copiar", { excluidos: tipo == "excluidos", mover, diretorioPara: this.instancias[instancia].diretorioAtual, itensDiretorios: arrItensDiretorios, itensArquivos: arrItensArquivos }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
			} else {
				//* Copia as versoes para o diretorio
				await api.put(storeAuth.apiNuvem(instancia) + "/arquivo/copiar/versao", { mover, diretorioPara: this.instancias[instancia].diretorioAtual, itens: arrItensArquivos }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
			}
		},
		async excluir(instancia) {
			const storeAuth = useAuthStore();

			var arrItensDiretorios = [];
			var arrItensArquivos = [];
			var indexItem = 0;

			//* Verifica se esta executando em uma instancia remota
			if (this.instancias[instancia].tipo == "remoto") {
				//* Carrega keys selecionadas
				var keysSelecionadas = Object.keys(this.instancias[instancia].selecionados);

				//* Percorre todos os itens selecionados
				for (indexItem = 0; indexItem < keysSelecionadas.length; indexItem++) {
					if (this.instancias[instancia].selecionados[keysSelecionadas[indexItem]].isDiretorio == true) {
						arrItensDiretorios.push(this.instancias[instancia].selecionados[keysSelecionadas[indexItem]]._id);
					} else if (this.instancias[instancia].selecionados[keysSelecionadas[indexItem]].isArquivo == true) {
						arrItensArquivos.push(this.instancias[instancia].selecionados[keysSelecionadas[indexItem]]._id);
					}
				}

				//* Verifica o que esta sendo excluido
				if (this.instancias[instancia].view == "diretorio" || this.instancias[instancia].view == "compartilhamentos" || this.instancias[instancia].view == "pesquisa") {
					//* Exclui os arquivos
					await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/excluir", { itensDiretorios: arrItensDiretorios, itensArquivos: arrItensArquivos }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);

					//* Carregar os itens
					this.carregarItens(instancia, true);
				} else if (this.instancias[instancia].view == "versoes") {
					//* Exclui as versoes
					var responseExcluirVersao = await api.put(storeAuth.apiNuvem(instancia) + "/arquivo/excluir/versao", { arquivo: this.instancias[instancia].arquivoAtual._id, versoes: arrItensArquivos }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);

					//* Verifica se ocorreu algum erro
					if (responseExcluirVersao.data.erro == false) {
						//* Verifica se excluiu o arquivo
						if (responseExcluirVersao.data.arquivo == true) {
							//* Define a visualizacao
							this.instancias[instancia].view = "diretorio";

							//* Limpa o arquivo filtrado
							this.instancias[instancia].arquivoAtual = {};

							//* Carrega os itens com a selecao ja definida
							this.carregarItens(instancia, true);
						} else {
							this.carregarItens(instancia, true);
						}
					}
				}
			} else if (this.instancias[instancia].tipo == "local") {
				//* Percorre todos os itens da arvore
				for (indexItem = 0; indexItem < this.instancias[instancia].itens.length; indexItem++) {
					//* Verifica se o id consta no array o id selecionado
					if (this.instancias[instancia].selecionados[this.instancias[instancia].itens[indexItem]._id]) {
						//fs.rmSync(this.instancias[instancia].itens[indexItem]._id, { recursive: true, force: false });
						await trash([this.instancias[instancia].itens[indexItem]._id]);
					}
				}

				this.carregarItensLocal(instancia, true);
			}
		},
		async restaurarVersao(instancia) {
			const storeAuth = useAuthStore();

			//* Verifica se esta executando em uma instancia remota
			if (this.instancias[instancia].tipo == "remoto") {
				//* Carrega as keys dos selecionados
				var selecionadosKey = Object.keys(this.instancias[instancia].selecionados);

				//* Verifica se foi selecionado uma versao
				if (selecionadosKey.length > 0) {
					//* Restaura uma versao
					await api.put(storeAuth.apiNuvem(instancia) + "/arquivo/restaurar/" + this.instancias[instancia].arquivoAtual._id, { versao: this.instancias[instancia].selecionados[selecionadosKey[0]].id }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				}
			}
		},
		async restaurarExcluido(instancia) {
			const storeAuth = useAuthStore();

			//* Verifica se esta executando em uma instancia remota
			if (this.instancias[instancia].tipo == "remoto") {
				//* Carrega as keys dos selecionados
				var selecionadosKey = Object.keys(this.instancias[instancia].selecionados);

				//* Verifica se foi selecionado uma versao
				if (selecionadosKey.length > 0) {
					
					var mensagemErro = "";
															
					//* Percorre os itens selecionados
					for(let indiceSelecionado = 0; indiceSelecionado < selecionadosKey.length; indiceSelecionado++)
					{

						let response = null;

						//* Verifica se esta tratando diretorio
						if (this.instancias[instancia].selecionados[selecionadosKey[0]].isDiretorio == true) {
							//* Restaura o diretorio
							response = await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/restaurar/excluido/" + selecionadosKey[indiceSelecionado], {}, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
						} else if (this.instancias[instancia].selecionados[selecionadosKey[0]].isArquivo == true) {
							//* Restaura o arquivo
							response = await api.put(storeAuth.apiNuvem(instancia) + "/arquivo/restaurar/excluido/" + selecionadosKey[indiceSelecionado], {}, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
						}

						//* Valida o retorno
						api.valid(response.data, this.$route);

						//* Verifica se restaurou com sucesso
						if (response.data.erro == true) {
							mensagemErro = "Alguns itens não foram restaurados porque já constam outros com o mesmo nome no destino";
						}

					}

					//* Recarrega os itens da lista
					this.carregarItens(instancia, true);

					//* Retorna erro caso exista
					return mensagemErro;

				}

			}

			return "";
		},
		async abrirArquivoOS(instancia) {
			//* Verifica o tipo de instancia
			if (this.instancias[instancia].tipo == "local") {
				//* Percorre todos os itens da arvore
				for (var indexItem = 0; indexItem < this.instancias[instancia].itens.length; indexItem++) {
					//* Verifica se o id consta no array o id selecionado
					if (this.instancias[instancia].selecionados[this.instancias[instancia].itens[indexItem]._id]) {
						//* Abrir item no sistema operacional
						shellElectron.openPath(this.instancias[instancia].itens[indexItem]._id);
						break;
					}
				}
			}
		},
		async renomearCancelar(instancia, indexItem) {
			//* Recupera o nome original
			this.instancias[instancia].itens[indexItem].nome = this.instancias[instancia].itens[indexItem].nomeOriginal;

			//* Cancela o item a ser renomeado
			delete this.instancias[instancia].itens[indexItem].nomeOriginal;
			delete this.instancias[instancia].itens[indexItem].status;
		},
		async renomearSalvar(instancia, index) {
			const storeAuth = useAuthStore();
			//* Carrega o arquivo a ser editado
			var item = this.instancias[instancia].itens[index];

			//* Verifica se esta executando a instancia local
			if (this.instancias[instancia].tipo == "local") {
				var novoNome = null;

				//* Verifica o tipo
				if (item.isArquivo) {
					//* Renomeia o arquivo
					novoNome = path.resolve(path.dirname(item._id), item.nome + item.tipo);
				} else {
					//* Renomeia o diretorio
					novoNome = path.resolve(path.dirname(item._id), item.nome);
				}

				//* Verifica se renomeou para o mesmo item
				if (item._id == novoNome) {
					//* Deleta o status atual
					delete this.instancias[instancia].itens[index].status;
				}

				//* Verifica se o nome ja existe
				if (fs.existsSync(novoNome)) {
					//* Define a mensagem de erro
					this.instancias[instancia].itens[index].erroMensagem = item.isArquivo ? "Arquivo já existe" : "Diretório já existe";
					return;
				}

				//* Executa o meto de renomear
				fs.renameSync(item._id, novoNome);

				//* Define o novo id
				this.instancias[instancia].itens[index]._id = novoNome;
				this.instancias[instancia].itens[index].fullPath = novoNome;

				//* Deleta o status atual
				delete this.instancias[instancia].itens[index].status;
			} else {
				var apiRequest = null;

				//* Verifica se é um arquivo
				if (item.isArquivo) {
					//* Envia o novo nome do arquivo
					apiRequest = await api.put(storeAuth.apiNuvem(instancia) + "/arquivo/renomear/" + item._id, { nome: item.nome }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				} else {
					//* Envia o novo nome do diretorio
					apiRequest = await api.put(storeAuth.apiNuvem(instancia) + "/diretorio/renomear/" + item._id, { nome: item.nome }, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);
				}

				//* Verifica se ocorreu algum erro
				if (!apiRequest.data.erro) {

					if(this.instancias[instancia].itens[index].fullPath)
					{
						//* Monta o novo path
						var fullPath = this.instancias[instancia].itens[index].fullPath.toString();
						var parentFullPath = fullPath.substring(0, fullPath.lastIndexOf('/'))
						
						//* Atualiza o fullpath do item
						this.instancias[instancia].itens[index].fullPath = parentFullPath + (parentFullPath == "/" ? "" : "/") + item.nome;
					}
					//* Deleta o status atual
					delete this.instancias[instancia].itens[index].status;
				} else {
					//* Define a mensagem de erro
					this.instancias[instancia].itens[index].erroMensagem = apiRequest.data.mensagem;
				}
			}
		},
		async transferir(instancia, itensDefinidos = null) {
			const storeUpload = useStoreUpload();
			const storeAuth = useAuthStore();

			var filaId = null;
			//var arquivoSelecionado = false;
			var diretorioIdPrincipal = null;
			var diretorioId = null;

			//* Carrega o array de selecionados
			var arrSelecionados = Object.keys(this.instancias[instancia].selecionados);

			//* Verifica se consta itens selecionados
			if (arrSelecionados.length == 0) {
				return;
			}

			//* Verifica se foram definido itens
			if (itensDefinidos !== null) {
				arrSelecionados = itensDefinidos;
			}

			//* Define a instancia inversa
			var instanciaDestino = instancia == "esquerda" ? "direita" : "esquerda";

			//* Verifica se estra transferindo entre local e local
			if (this.instancias[instancia].tipo == "local" && this.instancias[instanciaDestino].tipo == "local") {
				//* Carreg aos itens selecionados e cola os itens
				await this.colarLocalLocal(instanciaDestino, this.copiar(instancia, false));
			}

			//* Verifica se estra transferindo entre remoto e remoto
			if (this.instancias[instancia].tipo == "remoto" && this.instancias[instanciaDestino].tipo == "remoto") {
				//* Carreg aos itens selecionados e cola os itens
				await this.colarRemotoRemoto(false, instanciaDestino, this.copiar(instancia, false), this.instancias[instancia].view == "versoes" ? "versoes" : "arquivos");
			}

			//* Verifica se esta transferindo de local para remoto ou remoto para local
			if (this.instancias[instancia].tipo !== this.instancias[instanciaDestino].tipo) {
				//* Verifica se consta itens selecionados
				if (arrSelecionados.length > 0) {
					//* Cria a fila
					if (filaId == null) {
						var instanciaRemota = "";

						//* Verifica se alguma das instancias é remota
						if (this.instancias[instancia].tipo == "remoto") {
							instanciaRemota = instancia;
						} else if (this.instancias[instanciaDestino].tipo == "remoto") {
							instanciaRemota = instanciaDestino;
						}

						//* Cria a fila de transferencia
						filaId = storeUpload.criarFila(this.instancias[instancia].tipo == "local" ? "upload" : "download", storeAuth.apiNuvem(instanciaRemota), storeAuth.token[instanciaRemota] !== "" ? storeAuth.token[instanciaRemota] : null);

						//* Define o id do diretorio principal
						diretorioIdPrincipal = this.instancias[instancia].tipo == "local" ? this.instancias[instancia].barraPath : this.instancias[instancia].diretorioAtual;

						//* Insere o diretorio principal para ficar no topo da lista
						storeUpload.criarFilaDiretorio(filaId, diretorioIdPrincipal, null, null, this.instancias[instancia].barraPath, this.instancias[instancia].tipo, false, { caminhoParent: this.instancias[instanciaDestino].barraPath, diretorioParent: this.instancias[instancia].tipo == "local" ? this.instancias[instanciaDestino].diretorioAtual : "" });

						//* Insere nos subdiretorios esse diretorio principal
						storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal] = true;
					}
				}

				//* Percorre os itens selecionados
				for (var indexItem = 0; indexItem < arrSelecionados.length; indexItem++) {
					//* Instancia o item selecionado
					var selecionado = null;

					//* Verifica se deve carregar da lista fixa
					if (itensDefinidos !== null) {
						selecionado = arrSelecionados[indexItem];
					} else {
						selecionado = this.instancias[instancia].selecionados[arrSelecionados[indexItem]];
					}

					//* Verifica se é um arquivo
					if (selecionado.isArquivo) {
						//* Define que pelo menos um arquivo foi selecionado
						//arquivoSelecionado = true;

						//* Verifica se é uma instancia local
						if (this.instancias[instancia].tipo == "local") {
							await storeUpload.uploadArquivo(filaId, selecionado.nome + selecionado.tipo, selecionado.fullPath, this.instancias[instanciaDestino].diretorioAtual, this.instancias[instanciaDestino].barraPath);
						} else {
							await storeUpload.downloadArquivo(filaId, selecionado, this.instancias[instanciaDestino].barraPath, this.instancias[instancia].diretorioAtual);
						}
					} else {
						if (this.instancias[instancia].tipo == "local") {
							//* Define o id do diretorio
							diretorioId = selecionado.fullPath;

							//* Cria o diretorio na fila como upload
							storeUpload.criarFilaDiretorio(filaId, diretorioId, null, null, selecionado.fullPath, this.instancias[instancia].tipo, true, { caminhoParent: this.instancias[instanciaDestino].barraPath, diretorioParent: this.instancias[instanciaDestino].diretorioAtual });
						} else {
							//* Define o id do diretorio
							diretorioId = selecionado._id;

							//* Cria o diretorio na fila como download
							storeUpload.criarFilaDiretorio(filaId, diretorioId, null, null, selecionado.fullPath, this.instancias[instancia].tipo, true, { caminhoParent: this.instancias[instanciaDestino].barraPath, diretorioParent: "" });
						}

						//* Insere o diretorio na lista principal
						storeUpload.fila[filaId].subdiretorios[diretorioId] = true;
					}
				}

				//* Verifica se foi criada alguma fila
				if (filaId !== null) {
					//* Define que finalizou de carregar esta fila
					storeUpload.fila[filaId].carregando = false;
				}

				/*
						Desabilitado

				//* Verifica se nao foi inserido nenhum arquivo no diretorio principal
				if (filaId !== null && arquivoSelecionado == false) {
					//* Remove o diretorio principal caso nenhum arquivo selecionado para ele
					delete storeUpload.fila[filaId].diretorios[diretorioIdPrincipal];
					delete storeUpload.fila[filaId].subdiretorios[diretorioIdPrincipal];
				}*/
			}

			//* Manda executar o proximo arquivo
			storeUpload.filasProximoArquivoTimer(1500);
		},
		selecionar(event, instancia, item, itemIndex) {
			if (item.isDiretorioPai && item.isDiretorioPai == true) {
				return;
			}

			if (item.status && item.status == "diretorio-novo") {
				return;
			}

			//* Define o fullpath caso diretorio remoto
			/*if (this.instancias[instancia].tipo == "remoto" && item.isDiretorio) {
				item.fullPath = this.instancias[instancia].barraPath + (this.instancias[instancia].barraPath == "/" ? "" : "/") + item.nome;
			}*/

			//* Limpa as selecoes anterior caso nenhuma tecla de selecao multipla pressionada
			if (event.metaKey == false && event.ctrlKey == false && event.shiftKey == false) {
				this.instancias[instancia].selecionados = {};
				this.instancias[instancia].selecionadosIndex = null;
			}

			//* Verifica se ja esta selecionado
			if (this.instancias[instancia].selecionados[item._id] && event.shiftKey == false) {
				//* Remove da selecao
				delete this.instancias[instancia].selecionados[item._id];

				//* Insere o indice tratado
				//this.instancias[instancia].selecionadosIndex = null;
			} else {
				//* Verifica se esta selecionando com shift
				if (event.shiftKey && this.instancias[instancia].selecionadosIndex !== null) {
					var novaSelecao = {};

					//* Define o maior e menor indice da selecao
					var novaSelecaoIndice = { maior: 0, menor: 0 };

					//* Define menor e maior indices
					novaSelecaoIndice.menor = this.instancias[instancia].selecionadosIndex < itemIndex ? this.instancias[instancia].selecionadosIndex : itemIndex;
					novaSelecaoIndice.maior = this.instancias[instancia].selecionadosIndex > itemIndex ? this.instancias[instancia].selecionadosIndex : itemIndex;

					//* Percorre da selecao atual ate a ultima selecao
					for (var indiceShiftSelecao = novaSelecaoIndice.menor; indiceShiftSelecao <= novaSelecaoIndice.maior; indiceShiftSelecao++) {
						//* Carrega o item da faixa de selecao
						var itemShiftSelecao = this.instancias[instancia].itens[indiceShiftSelecao];

						//* Seleciona o item da faixa
						novaSelecao[itemShiftSelecao._id] = itemShiftSelecao;
					}

					//* Define a nova selecao
					this.instancias[instancia].selecionados = novaSelecao;
				} else {
					//* Insere na selecao
					this.instancias[instancia].selecionados[item._id] = item;

					//* Insere o indice tratado
					this.instancias[instancia].selecionadosIndex = itemIndex;
				}
			}
		},
		async selecionarProximoItem(instancia, posicionar) {
			//* Verifica se tem itens selecionados
			if (this.instancias[instancia].itens.length == 0 || (this.instancias[instancia].itens.length == 1 && this.instancias[instancia].itens[0].nome == "... ")) {
				return;
			}

			//* Carrega o array de selecionados
			var arrSelecionados = Object.keys(this.instancias[instancia].selecionados);

			var novaPosicao = 0;

			//* Verifica se consta itens selecionados
			if (arrSelecionados.length == 0) {
				//* Verifica se o diretorio é de retorno
				if (this.instancias[instancia].itens[novaPosicao].nome == "... ") {
					novaPosicao = 1;
				}
			} else if (arrSelecionados.length == 1) {
				//* Verifica se o diretorio é de retorno
				/*if (this.instancias[instancia].itens[novaPosicao].nome == "... ") {
					novaPosicao = 1;
				}*/

				//* Define a nova posicao
				novaPosicao = this.instancias[instancia].selecionadosIndex + posicionar;
			}

			//* Verifica se esta tentando posicionar fora do indice
			if (novaPosicao >= this.instancias[instancia].itens.length || novaPosicao < 0) {
				return;
			}

			//* Coloca o foco no item posicionado
			document.getElementById("itemDiretorio_" + instancia + "_" + novaPosicao).scrollIntoView({ behavior: "smooth", block: "center" });

			//* Seleciona o item
			await this.selecionar({ metaKey: false, ctrlKey: false, shiftKey: false }, instancia, this.instancias[instancia].itens[novaPosicao], novaPosicao);
		},
		isSelecionado(instancia, itemId) {
			return this.instancias[instancia].selecionados[itemId] ? true : false;
		},
		selecionadosQuantidade(instancia) {
			if (!this.instancias[instancia] || !this.instancias[instancia].selecionados) {
				return 0;
			}
			return Object.keys(this.instancias[instancia].selecionados).length;
		},
		isSelecionadoArquivo(instancia) {
			if (!this.instancias[instancia] || !this.instancias[instancia].selecionados || Object.keys(this.instancias[instancia].selecionados).length !== 1) {
				return false;
			}

			return this.instancias[instancia].selecionados[Object.keys(this.instancias[instancia].selecionados)[0]].isArquivo == true;
		},
		selecaoMultipla(instancia, item, check) {
			//* Verifica se deve inserir ou remover
			if (check == true) {
				this.instancias[instancia].selecaoMultipla[item._id] = item;
			} else {
				delete this.instancias[instancia].selecaoMultipla[item._id];
			}
		},
		pastaAnterior(instancia) {
			this.diretorioIr(instancia, this.instancias[instancia].diretorioAnterior, this.instancias[instancia].tipo == "remoto" ? this.instancias[instancia].diretorioAnterior : "");
		},
		pastaAcima(instancia) {
			this.diretorioIr(instancia, this.instancias[instancia].diretorioPai, this.instancias[instancia].tipo == "remoto" ? this.instancias[instancia].diretorioPai : "");
		},
		async downloadAnexo(instancia) {
			const storeAuth = useAuthStore();

			var arrItensDiretorios = [];
			var arrItensArquivos = [];
			var arrItensVersoes = [];

			var itemDownload = null;

			//* Carrega o array de selecionados
			var arrSelecionados = Object.keys(this.instancias[instancia].selecionados);

			//* Verifica se consta itens selecionados
			if (arrSelecionados.length == 0) {
				return;
			}

			//* Percorre todos os itens da area de transferencia
			for (var indexItemDownload = 0; indexItemDownload < arrSelecionados.length; indexItemDownload++) {
				itemDownload = this.instancias[instancia].selecionados[arrSelecionados[indexItemDownload]];

				if (itemDownload.isDiretorio == true) {
					arrItensDiretorios.push(itemDownload._id);
				} else if (itemDownload.isArquivo == true) {

					//* Desmembra a versao
					var arrIdArquivo = itemDownload._id.split("_");

					//* Verifica se consta uma versao especifica
					if(arrIdArquivo.length > 0)
					{

						//* Insere o arquivo com a versao
						arrItensArquivos.push(arrIdArquivo[0]);
						arrItensVersoes.push(arrIdArquivo[1]);
						
					}else{
						arrItensArquivos.push(itemDownload._id);
					}

				}
			}

			//* Restaura o diretorio
			let responseDownload = await api.post(storeAuth.apiNuvem(instancia) + "/download/request", {itensArquivos:arrItensArquivos, itensVersoes: arrItensVersoes, itensDiretorios:arrItensDiretorios}, storeAuth.token[instancia] !== "" ? storeAuth.token[instancia] : null);

			//* Verifica se ocorreu erro
			if(responseDownload.data.erro == false)
			{

				//* Verifica se foi concluido
				if(responseDownload.data.status == "concluido")
				{
					//* Efetuar o download do arquivo
					this.downloadUrl(storeAuth.apiNuvem(instancia) + "/download/" +   funcoes.customEncodeURIComponent(responseDownload.data.key) + "?attachment=true", responseDownload.data.nome + responseDownload.data.tipo);
				}else{
					funcoes.novaJanela("/app/download" + storeAuth.apiNuvem(instancia,false) + "/" + funcoes.customEncodeURIComponent(responseDownload.data.key), 300, 400);
				}

			}

		},
		downloadUrl(url, filename) {
			// Create a new anchor element
			const a = document.createElement("a");

			// Set the href and download attributes for the anchor element
			// You can optionally set other attributes like `title`, etc
			// Especially, if the anchor element will be attached to the DOM
			a.href = url;
			a.download = filename || "download";

			// Click handler that releases the object URL after the element has been clicked
			// This is required for one-off downloads of the blob content
			const clickHandler = () => {
				setTimeout(() => {
					URL.revokeObjectURL(url);
					a.removeEventListener("click", clickHandler);
				}, 150);
			};

			// Add the click event listener on the anchor element
			// Comment out this line if you don't want a one-off download of the blob content
			a.addEventListener("click", clickHandler, false);

			// Programmatically trigger a click on the anchor element
			// Useful if you want the download to happen automatically
			// Without attaching the anchor element to the DOM
			// Comment out this line if you don't want an automatic download of the blob content
			a.click();

			// Return the anchor element
			// Useful if you want a reference to the element
			// in order to attach it to the DOM or use it in some other way
			return a;
		}
	}
});
