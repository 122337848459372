<template>
  <div>
    <Dialog :id="'dialogProcurar'" ref="dialogProcurar" :hide-header="true" :hideFooter="true" :modal="true"
      :tamanho="'xl'" :classModalBody="'mx-5 mx-xl-1 my-1'" :titulo="'Procurar'" @dialog-off="fechar()">
      <template #header>
        <div class="me-9 ms-9 mb-0 mt-5">
          <div class="d-flex">
            <div class="flex-grow-1">
              <h1 class="d-flex fs-2 mt-2">
                <span class="fs-7 ms-2 mt-2 cursor-pointer" :class="{
                  'fw-bolder': view == 'arquivos',
                  'text-success': view == 'arquivos',
                  'fw-normal': view !== 'arquivos',
                  'text-dark': view !== 'arquivos',
                }" @click="view = 'arquivos'">Arquivos</span>
                <span class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"></span>
                <span class="fs-7 ms-2 mt-2 cursor-pointer" :class="{
                  'fw-bolder': view == 'diretorios',
                  'text-success': view == 'diretorios',
                  'fw-normal': view !== 'diretorios',
                  'text-dark': view !== 'diretorios',
                }" @click="view = 'diretorios'">Diretorios</span>
              </h1>
            </div>

            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                    fill="currentColor"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                    fill="currentColor"></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="separator m-0 mt-3"></div>
        </div>
      </template>
      <div>
        <div :class="{ 'd-none': view != 'arquivos' }">

          <DiretorioList
            ref="procurarDiretorioList"
            instancia="procurarDiretorioList"
            tipo="remoto"
            :funcao="'selecionar-diretorio'"
            @dialog-on="$refs.dialogProcurar.fechar()"
            @dialog-off="$refs.dialogProcurar.abrir()"
          />

        </div>
        <div :class="{ 'd-none': view != 'diretorios' }">
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import DialogMensagem from "../components/ComponentDialogMensagem.vue";
import DiretorioList from "./DiretorioList.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";

export default {
  components: {
    Dialog,
    DialogMensagem,
    DiretorioList
  },
  props: {
    instancia: { type: String, default: "" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },
  data() {
    return {
      timerFiltroTexto: null,
      instancia: "",
      carregandoDadosBasicos: false,
      carregandoDadosTotais: false,
      item: {
        _id: "",
        nuvem: "",
        nome: "",
        fullPath: "",
        arquivos: 0,
        tamanho: 0,
        tamanhoVersoes: 0,
        logDiasLimpeza: 0,
        diasLimpeza: 0,
        versoesArquivo: 0,
        editandoDiasLimpeza: false,
        editandoVersoesArquivo: false,
        criptografia: 0,
        direitoUsuarios: [],
        direitoGrupos: [],
        direitoUsuariosOpcoes: [],
        direitoGruposOpcoes: [],
        compartilhamento: {},
      },
      itemCompartilhamento: {
        nome: "",
      },
      itemCompartilhamentoErros: {
        nome: { status: false, mensagem: "" },
      },
      temp: {
        diasLimpeza: 0,
        versoesArquivo: 0
      },
      usuariosDados: {},
      gruposDados: {},
      view: "info",
      diretorioRoot: false,
    };
  },

  mounted() { },

  methods: {
    abrir() {
      this.$emit("dialog-on");

      //* Define que consta uma dialog aberta
      this.storeArvore.dialogAberta = true;

      //* Define a view
      this.storeArvore.instancias["procurarDiretorioList"].view = "pesquisa";

      //* Define a visualizacao padrao
      this.view = "arquivos";

      //this.carregarItens();
      this.$refs.dialogProcurar.abrir();
    },
    fechar() {
      //* Define que consta uma dialog fechada
      this.storeArvore.dialogAberta = false;

      this.$refs.dialogProcurar.fechar();
      this.$emit("dialog-off");
    }
  },
};
</script>

<style></style>
